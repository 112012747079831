module.exports = {
  "lang_markread": "označiť ako prečítané",
  "lang_filter": "Zobrazovať",
  "lang_newest": "najnovšie",
  "lang_unread": "neprečítané",
  "lang_starred": "označené",
  "lang_tags": "Tagy",
  "lang_alltags": "všetky tagy",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "pred {0,plural,one {1 hodinou},other {# hodinami}}",
  "lang_minutes": "pred {0,plural,one {1 minútou},other {# minútami}}",
  "lang_seconds": "pred {0,plural,one {chvíľou},other {# sekundami}}",
  "lang_star": "označiť",
  "lang_unstar": "odznačiť",
  "lang_mark": "označiť ako prečítané",
  "lang_unmark": "označiť ako neprčítané",
  "lang_load_img": "nahrať obrázky",
  "lang_open_window": "otvoriť",
  "lang_next": "ďalšie",
  "lang_searchbutton": "vyhľadať",
  "lang_searchremove": "skryť vyhľadávanie",
  "lang_refreshbutton": "obnoviť zdroje",
  "lang_settingsbutton": "nastavenia",
  "lang_logoutbutton": "odhlásiť sa",
  "lang_loginbutton": "prihlásiť sa",
  "lang_sources": "Zdroje",
  "lang_source_add": "pridať zdroj",
  "lang_source_opml": "alebo nahrať opml súbor",
  "lang_source_export": "export zdrojov",
  "lang_source_edit": "upraviť",
  "lang_source_filter": "Filter",
  "lang_source_delete": "zmazať",
  "lang_source_warn": "Naozaj chcete tento zdroj zmazať?",
  "lang_source_new": "nový neuložený zdroj",
  "lang_source_title": "Názov",
  "lang_source_tags": "Tagy",
  "lang_source_comma": "oddelené čiarkou",
  "lang_source_select": "vyberte prosím zdroj",
  "lang_source_type": "Typ",
  "lang_source_save": "uložiť",
  "lang_source_cancel": "zrušiť",
  "lang_source_saved": "uložené",
  "lang_no_entries": "žiadny záznam",
  "lang_more": "víac",
  "lang_login": "prihlásiť",
  "lang_login_username": "meno",
  "lang_login_password": "heslo",
  "lang_no_title": "Bez titulku",
  "lang_error": "nastala chyba"
}
;