module.exports = {
  "lang_markread": "merkitse luetuiksi",
  "lang_newest": "uusimmat",
  "lang_unread": "lukemattomat",
  "lang_starred": "t&auml;hdelliset",
  "lang_tags": "Avainsanat",
  "lang_alltags": "kaikki avainsanat",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 tunti},other {# tuntia}} sitten",
  "lang_minutes": "{0,plural,one {1 minuutti},other {# minuuttia}} sitten",
  "lang_seconds": "{0,plural,one {1 sekunti},other {# sekuntia}} sitten",
  "lang_star": "t&auml;hti",
  "lang_unstar": "t&auml;hti pois",
  "lang_mark": "luetuksi",
  "lang_unmark": "lukemattomaksi",
  "lang_load_img": "lataa kuvat",
  "lang_open_window": "avaa",
  "lang_searchbutton": "etsi",
  "lang_sources": "L&auml;hteet",
  "lang_source_add": "lis&auml;&auml; l&auml;hde",
  "lang_source_opml": "tai tuo opml-tiedosto tai google reader",
  "lang_source_export": "vie l&auml;hteet",
  "lang_source_edit": "muokkaa",
  "lang_source_delete": "poista",
  "lang_source_warn": "Haluatko varmasti poistaa t&auml;m&auml;n l&auml;hteen?",
  "lang_source_new": "uusi tallentamaton l&auml;hde",
  "lang_source_title": "Nimi",
  "lang_source_tags": "Avainsanat",
  "lang_source_comma": "avainsanat erotetaan pilkulla",
  "lang_source_select": "valitse l&auml;hde",
  "lang_source_type": "Tyyppi",
  "lang_source_save": "tallenna",
  "lang_source_cancel": "peruuta",
  "lang_source_saved": "tallennettu",
  "lang_no_entries": "merkint&ouml;j&auml; ei l&ouml;ydetty",
  "lang_more": "lis&auml;&auml;",
  "lang_login": "kirjaudu sis&auml;&auml;n",
  "lang_login_username": "k&auml;ytt&auml;j&auml;tunnus",
  "lang_login_password": "salasana",
  "lang_no_title": "ei otsikkoa",
  "lang_error_share_native_abort": "Kohteen jakaminen epäonnistui – joko jakamiskohteita ei ole tai peruutat sen.",
  "lang_close_entry": "Sulje",
  "lang_login_invalid_credentials": "Väärä käyttäjänimi/salasana",
  "lang_share_mail_label": "Jaa sähköpostitse"
}
;