module.exports = {
  "lang_markread": "oznacz jako przeczytane",
  "lang_filter": "filtr",
  "lang_newest": "najnowsze",
  "lang_unread": "nieprzeczytane",
  "lang_starred": "z gwiazdką",
  "lang_tags": "Tagi",
  "lang_alltags": "wszystkie",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "przed {0,plural,one {godziną},other {# godzinami}}",
  "lang_minutes": "przed {0,plural,one {minutą},other {# minutami}}",
  "lang_seconds": "przed {0,plural,one {chwilą},other {# sekundami}}",
  "lang_days": "{0,plural,zero {dzisiaj},one {wczoraj},other {przejrzany # dni temu}}",
  "lang_star": "oznacz gwiazdką",
  "lang_unstar": "usuń gwiazdkę",
  "lang_mark": "oznacz jako przeczytane",
  "lang_unmark": "oznacz jako nieprzeczytane",
  "lang_load_img": "pobierz obrazy",
  "lang_open_window": "otwórz",
  "lang_next": "następny",
  "lang_searchbutton": "szukaj",
  "lang_searchremove": "ukryj wyszukiwanie",
  "lang_refreshbutton": "odśwież",
  "lang_settingsbutton": "opcje",
  "lang_logoutbutton": "wyloguj",
  "lang_loginbutton": "zaloguj",
  "lang_sources": "Źródła",
  "lang_source_add": "dodaj źródło",
  "lang_source_opml": "lub zaimportuj plik OPML z Google Reader",
  "lang_source_export": "eksportuj źródła",
  "lang_source_edit": "edytuj",
  "lang_source_filter": "Filtr",
  "lang_source_delete": "usuń",
  "lang_source_warn": "Czy na pewno chcesz usunąć to źródło?",
  "lang_source_new": "nowe niezapisane źródło",
  "lang_source_title": "Tytuł",
  "lang_source_autotitle_hint": "Pozostaw puste, aby uzupełnić automatycznie",
  "lang_source_tags": "Tagi",
  "lang_source_pwd_placeholder": "bez zmian",
  "lang_source_comma": "rozdzielone przecinkami",
  "lang_source_select": "proszę wybrać typ źródła",
  "lang_source_type": "Typ",
  "lang_source_save": "zapisz",
  "lang_source_cancel": "anuluj",
  "lang_source_saved": "zapisano",
  "lang_source_last_post": "Ostatni artykuł został przejrzany ",
  "lang_source_refresh": "Odśwież to źródło",
  "lang_no_entries": "brak wpisów",
  "lang_more": "więcej",
  "lang_login": "zaloguj",
  "lang_login_username": "użytkownik",
  "lang_login_password": "hasło",
  "lang_login_invalid_credentials": "nieprawidłowy login lub hasło.",
  "lang_no_title": "bez tytułu",
  "lang_error": "wystąpił błąd",
  "lang_streamerror": "Nie załadowano żadnych elementów, odświeżyć?"
}
;