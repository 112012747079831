module.exports = {
  "lang_share_mail_label": "Compartilhar usando correio eletrônico",
  "lang_error": "um erro ocorreu",
  "lang_no_title": "sem título",
  "lang_login_password": "palavra-passe",
  "lang_login_username": "utilizador",
  "lang_login": "entrar",
  "lang_more": "mais",
  "lang_no_entries": "nenhum registo encontrado",
  "lang_source_saved": "gravado",
  "lang_source_cancel": "cancelar",
  "lang_source_save": "gravar",
  "lang_source_type": "Tipo",
  "lang_source_select": "por favor, selecione a fonte",
  "lang_source_comma": "separado por vírgula",
  "lang_source_tags": "Marcadores",
  "lang_source_title": "Título",
  "lang_source_new": "nova fonte (não salva)",
  "lang_source_warn": "realmente deletar esta fonte?",
  "lang_source_delete": "deletar",
  "lang_source_filter": "Filtro",
  "lang_source_edit": "editar",
  "lang_source_export": "exportar fontes",
  "lang_source_opml": "ou importar ficheiro opml ou google reader",
  "lang_source_add": "adicionar fonte",
  "lang_sources": "Fontes",
  "lang_loginbutton": "entrar",
  "lang_logoutbutton": "sair",
  "lang_settingsbutton": "configurações",
  "lang_refreshbutton": "atualizar fontes",
  "lang_searchbutton": "procurar",
  "lang_next": "próximo",
  "lang_open_window": "abrir",
  "lang_load_img": "carregar Imagens",
  "lang_unmark": "marcar como não lido",
  "lang_mark": "marcar como lido",
  "lang_unstar": "remover estrela",
  "lang_star": "marcar com estrela",
  "lang_seconds": "{0,plural,one {1 segundo},other {# segundos}} atrás",
  "lang_minutes": "{0,plural,one {1 minuto},other {# minutos}} atrás",
  "lang_hours": "{0,plural,one {1 hora},other {# horas}} atrás",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_alltags": "todos os marcadores",
  "lang_tags": "Marcadores",
  "lang_starred": "com estrela",
  "lang_unread": "não lidos",
  "lang_newest": "novos",
  "lang_markread": "marcar como lido"
}
;