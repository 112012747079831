module.exports = {
  "lang_markread": "全部标记为已读",
  "lang_newest": "最新条目",
  "lang_unread": "未读条目",
  "lang_starred": "加星标的条目",
  "lang_tags": "标签",
  "lang_alltags": "所有标签",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 小时},other {# 小时}}前",
  "lang_minutes": "{0,plural,one {1 分钟},other {# 分钟}}前",
  "lang_seconds": "{0,plural,one {1 秒},other {# 秒}}前",
  "lang_days": "{0,plural,zero {今天},one {昨天},other {# 天前}}",
  "lang_star": "加星标",
  "lang_unstar": "取消星标",
  "lang_mark": "标记为已读",
  "lang_unmark": "标记为未读",
  "lang_load_img": "加载图片",
  "lang_open_window": "打开",
  "lang_searchbutton": "搜索",
  "lang_sources": "源",
  "lang_source_add": "添加源",
  "lang_source_opml": "或导入 OPML 文件/Google 阅读器订阅",
  "lang_source_export": "导出源",
  "lang_source_edit": "编辑",
  "lang_source_delete": "删除",
  "lang_source_warn": "确认要删除该源?",
  "lang_source_new": "新的源",
  "lang_source_title": "标题",
  "lang_source_tags": "标签",
  "lang_source_comma": "以逗号分隔",
  "lang_source_select": "请选择源",
  "lang_source_type": "类型",
  "lang_source_save": "保存",
  "lang_source_cancel": "取消",
  "lang_source_saved": "已保存",
  "lang_source_last_post": "更新于 ",
  "lang_no_entries": "没有条目",
  "lang_more": "更多",
  "lang_login": "登录",
  "lang_login_username": "用户名",
  "lang_login_password": "密码",
  "lang_no_title": "无标题",
  "lang_logoutbutton": "登出",
  "lang_loginbutton": "登录",
  "lang_error_logout": "不能登出：",
  "lang_settingsbutton": "设置",
  "lang_app_reload": "再启动"
}
;