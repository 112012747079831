module.exports = {
  "lang_markread": "marcar sco legì",
  "lang_newest": "pli novas",
  "lang_unread": "nunlegì",
  "lang_starred": "cun staila",
  "lang_tags": "Etichettas",
  "lang_alltags": "tut las etichettas",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "Avant {0,plural,one {1 ura},other {# uras}}",
  "lang_minutes": "Avant {0,plural,one {1 minuta},other {# minutas}}",
  "lang_seconds": "Avant {0,plural,one {1 secunda},other {# secundas}}",
  "lang_star": "agiuntar staila",
  "lang_unstar": "allontanar staila",
  "lang_mark": "marcar sco legì",
  "lang_unmark": "marcar sco nunlegì",
  "lang_load_img": "chargiar maletgs",
  "lang_open_window": "avrir",
  "lang_searchbutton": "tschertgar",
  "lang_sources": "Funtaunas",
  "lang_source_add": "agiuntar funtauna",
  "lang_source_opml": "u importar ina datoteca opml u da google reader",
  "lang_source_export": "exportar las funtaunas",
  "lang_source_edit": "modifitgar",
  "lang_source_delete": "stizzar",
  "lang_source_warn": "propi stizzar questa funtauna?",
  "lang_source_new": "nova funtauna betg memorisada",
  "lang_source_title": "Titel",
  "lang_source_tags": "Etichettas",
  "lang_source_comma": "seperà entras commas",
  "lang_source_select": "tscherna p.pl. la funtauna",
  "lang_source_type": "Tip",
  "lang_source_save": "memorisar",
  "lang_source_cancel": "interrumper",
  "lang_source_saved": "memorisà",
  "lang_no_entries": "chattà naginas endataziuns",
  "lang_more": "dapli",
  "lang_login": "s'annunziar",
  "lang_login_username": "num d'utilisader",
  "lang_login_password": "pled-clav",
  "lang_no_title": "nagin titel"
}
;