module.exports = {
  "lang_markread": "markeer gelezen",
  "lang_newest": "nieuwste",
  "lang_unread": "ongelezen",
  "lang_starred": "met ster",
  "lang_tags": "Tags",
  "lang_alltags": "alle tags",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 hour},other {# uren}} geleden",
  "lang_minutes": "{0,plural,one {1 minute},other {# minuten}} geleden",
  "lang_seconds": "{0,plural,one {1 second},other {# seconden}} geleden",
  "lang_days": "{0,plural,zero {vandaag},one {gisteren},other {# dagen geleden}}",
  "lang_star": "ster",
  "lang_unstar": "ster verwijderen",
  "lang_mark": "gelezen",
  "lang_unmark": "ongelezen",
  "lang_load_img": "afbeeldingen tonen",
  "lang_open_window": "open",
  "lang_searchbutton": "zoeken",
  "lang_sources": "Bron",
  "lang_source_add": "bron toevoegen",
  "lang_source_opml": "of importeer opml bestand of google reader",
  "lang_source_export": "exporteer bronnen",
  "lang_source_edit": "wijzig",
  "lang_source_delete": "verwijder",
  "lang_source_warn": "bron echt verwijderen?",
  "lang_source_new": "nieuwe bron",
  "lang_source_title": "Titel",
  "lang_source_tags": "Tags",
  "lang_source_comma": "komma gescheiden",
  "lang_source_select": "selecteer een bron",
  "lang_source_type": "Soort",
  "lang_source_save": "opslaan",
  "lang_source_cancel": "annuleren",
  "lang_source_saved": "opgeslagen",
  "lang_source_last_post": "Meest recente artikel is gevonden",
  "lang_no_entries": "geen items gevonden",
  "lang_more": "meer",
  "lang_login": "inloggen",
  "lang_login_username": "gebruikersnaam",
  "lang_login_password": "wachtwoord",
  "lang_no_title": "geen titel"
}
;