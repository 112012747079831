module.exports = {
  "lang_markread": "Elolvastam",
  "lang_newest": "Legfrissebb",
  "lang_unread": "Olvasatlan",
  "lang_starred": "Csillagos",
  "lang_tags": "Címkék",
  "lang_alltags": "Összes címke",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 hour},other {# hours}} ago",
  "lang_minutes": "{0,plural,one {1 minute},other {# minutes}} ago",
  "lang_seconds": "{0,plural,one {1 second},other {# seconds}} ago",
  "lang_star": "csillag",
  "lang_unstar": "csillagtalan",
  "lang_mark": "olvasottra",
  "lang_unmark": "olvasatlanra",
  "lang_load_img": "képbetöltés",
  "lang_open_window": "megnyit",
  "lang_searchbutton": "keresés",
  "lang_sources": "Forrás",
  "lang_source_add": "Új forrás hozzáadása",
  "lang_source_opml": "vagy opml fájlt vagy google reader forrást importálása",
  "lang_source_export": "Forrás exportálása",
  "lang_source_edit": "szerkeszt",
  "lang_source_delete": "töröl",
  "lang_source_warn": "valóban törölhető ez a forrás?",
  "lang_source_new": "új forrás hozzáadása",
  "lang_source_title": "Cím",
  "lang_source_tags": "Címke",
  "lang_source_comma": "vesszővel tagolva",
  "lang_source_select": "a típus kiválasztása",
  "lang_source_type": "Típus",
  "lang_source_save": "mentés",
  "lang_source_cancel": "mégsem",
  "lang_source_saved": "elmentve",
  "lang_no_entries": "nem talalható egy sem",
  "lang_more": "továbbiak",
  "lang_login": "Belépés",
  "lang_login_username": "Felhasználónév",
  "lang_login_password": "Jelszó",
  "lang_no_title": "nincs cím",
  "lang_filter": "Szűrő",
  "lang_online_count": "Tételek elérhetők a szerveren",
  "lang_offline_count": "Tételek elérhetők helyben",
  "lang_error_session_expired": "A munkamenet lejárt",
  "lang_app_reload": "Újratölt",
  "lang_sources_refreshed": "Források frissítve",
  "lang_reload_list": "Lista újratöltése",
  "lang_error_unwritable_logs": "A naplófájl nem írható.",
  "lang_error": "Hiba történt",
  "lang_source_pwd_placeholder": "Nem változott",
  "lang_source_filter": "Szűrő",
  "lang_loginbutton": "Bejelentkezés",
  "lang_logoutbutton": "Kijelentkezés"
}
;