module.exports = {
  "lang_markread": "atzīmēt kā izlasītu",
  "lang_filter": "filtrs",
  "lang_newest": "jaunākais",
  "lang_unread": "neizlasīts",
  "lang_starred": "ar zvaigznītēm",
  "lang_tags": "Birkas",
  "lang_alltags": "visas birkas",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "pirms {0,plural,one {1 stundas},other {# stundām}}",
  "lang_minutes": "pirms {0,plural,one {1 minūtes},other {# minūtēm}}",
  "lang_seconds": "pirms {0,plural,one {1 sekundes},other {# sekundēm}}",
  "lang_days": "{0,plural,zero {šodien},one {vakardien},other {pirms # dienām}}",
  "lang_star": "pievienot zvaigznīti",
  "lang_unstar": "noņemt zvaigznīti",
  "lang_mark": "atzīmēt kā izlasītu",
  "lang_unmark": "atzīmēt kā neizlasītu",
  "lang_load_img": "ielādēt bildes",
  "lang_open_window": "atvērt",
  "lang_next": "nākamais",
  "lang_searchbutton": "meklēt",
  "lang_searchremove": "slēpt meklēšanu",
  "lang_refreshbutton": "atsvaidzināt avotus",
  "lang_settingsbutton": "iestatījumi",
  "lang_logoutbutton": "iziet",
  "lang_loginbutton": "ieiet",
  "lang_sources": "Avoti",
  "lang_source_add": "pievienot avotu",
  "lang_source_opml": "vai importēt opml failu, vai google lasītāju",
  "lang_source_export": "eksportēt avotus",
  "lang_source_edit": "labot",
  "lang_source_filter": "Filtrs",
  "lang_source_delete": "dzēst",
  "lang_source_warn": "Vai tiešām vēlaties dzēst šo avotu?",
  "lang_source_new": "nesaglabāts avots",
  "lang_source_title": "Nosaukums",
  "lang_source_tags": "Birkas",
  "lang_source_pwd_placeholder": "nav mainījies",
  "lang_source_comma": "atdalīt ar komatu",
  "lang_source_select": "lūdzu izvēlieties avotu",
  "lang_source_type": "Tips",
  "lang_source_save": "saglabāt",
  "lang_source_cancel": "atcelt",
  "lang_source_saved": "saglabāts",
  "lang_source_last_post": "Pēdējā ziņa redzēta",
  "lang_no_entries": "nav ierakstu",
  "lang_more": "vēl",
  "lang_login": "ienākt",
  "lang_login_username": "Lietotājvārds",
  "lang_login_password": "Parole",
  "lang_no_title": "nav nosaukuma",
  "lang_error": "kļūda",
  "lang_streamerror": "Ziņas nav ielādētas, pārlādēt?"
}
;