import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ItemHeader = ({
  item,
  preventDefaultOnSmartphone,
  makeEntriesLink,
  anonymize,
  expanded,
  selected,
  handleKeyUp,
  compact,
  handleReadToggle,
  handleClick,
}) => {
  const { title, author, sourcetitle } = item;
  const relDate = selfoss.ui.datetimeRelative(item.datetime);
  return (
    <div className={classNames({ 'entry-header': true, 'entry-header-compact': compact })}>
      {/* tags */}
      {!compact && (
        <span className="entry-tags">
          {Object.entries(item.tags).map(([tag, color]) => (
            <div
              key={tag}
              className="entry-tags-tag"
              to={makeEntriesLink(location, {
                category: `tag-${tag}`,
                id: null,
              })}
              onClick={preventDefaultOnSmartphone}
            >
              <div
                className="colorIcon"
                style={{
                  color: color['foreColor'],
                  backgroundColor: color['backColor'],
                }}
              />
              {tag}
            </div>
          ))}
        </span>
      )}

      {/* icon */}
      <a
        href={anonymize(item.link)}
        className="entry-icon"
        tabIndex="-1"
        rel="noopener noreferrer"
        aria-hidden="true"
        onClick={preventDefaultOnSmartphone}
      >
        {item.icon !== null && item.icon.trim().length > 0 && item.icon != '0' ? (
          <img src={`favicons/${item.icon}`} aria-hidden="true" alt="" />
        ) : (
          <div className="icon-placeholder" />
        )}
      </a>

      <div
        className={classNames({
          'entry-title-container': true,
          'entry-title-container-compact': compact,
        })}
      >
        {/* title */}
        <h3 className="entry-title" onClick={(event) => handleClick(event)}>
          <span
            className="entry-title-link"
            aria-expanded={expanded}
            aria-current={selected}
            role="link"
            tabIndex="0"
            onKeyUp={handleKeyUp}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </h3>
        <div className="entry-subinfo">
          {/* source */}
          <span>{sourcetitle}</span>

          {/* author */}
          {author.trim() !== '' && !compact ? (
            <React.Fragment>
              <span className="entry-author">{author}</span>
            </React.Fragment>
          ) : null}

          {/* datetime */}
          {!compact && (
            <a
              href={anonymize(item.link)}
              className={classNames({
                'entry-datetime': true,
                timestamped: relDate === null,
              })}
              target="_blank"
              rel="noopener noreferrer"
              onClick={preventDefaultOnSmartphone}
            >
              {relDate !== null ? relDate : item.datetime.toLocaleString()}
            </a>
          )}

          {/* read time */}
          {!compact &&
          selfoss.config.readingSpeed !== null &&
          Math.round(item.wordCount / selfoss.config.readingSpeed) > 0 ? (
            <React.Fragment>
              <span className="entry-readtime">
                {selfoss.ui._('article_reading_time', [
                  Math.round(item.wordCount / selfoss.config.readingSpeed),
                ])}
              </span>
            </React.Fragment>
          ) : null}
        </div>
      </div>
      <div className="spacer" />
      <div className="headerButtons">
        <a
          href={anonymize(item.link)}
          target="_blank"
          rel="noopener noreferrer"
          className="entry-header-action"
        >
          <FontAwesomeIcon icon={['fas', 'external-link-alt']} /> {selfoss.ui._('open_window')}
        </a>
        <button
          className="entry-header-action"
          onClick={(event) => handleReadToggle({ event, entry: item })}
        >
          <FontAwesomeIcon icon={[`fa${item.unread == 1 ? 's' : 'r'}`, 'check-circle']} />{' '}
          {item.unread == 1 ? selfoss.ui._('mark') : selfoss.ui._('unmark')}
        </button>
      </div>
    </div>
  );
};

export default ItemHeader;
