module.exports = {
  "lang_markread": "Mark as read",
  "lang_filter": "Filter",
  "lang_newest": "Newest",
  "lang_unread": "Unread",
  "lang_starred": "Starred",
  "lang_online_count": "Items available on the server",
  "lang_offline_count": "Items available locally",
  "lang_offline_navigation_unavailable": "Switching tags and sources is currently not available in offline mode.",
  "lang_tags": "Tags",
  "lang_alltags": "All tags",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 hour},other {# hours}} ago",
  "lang_minutes": "{0,plural,one {1 minute},other {# minutes}} ago",
  "lang_seconds": "{0,plural,one {1 second},other {# seconds}} ago",
  "lang_days": "{0,plural,zero {today},one {yesterday},other {# days ago}}",
  "lang_star": "Star",
  "lang_unstar": "Unstar",
  "lang_mark": "Mark as read",
  "lang_unmark": "Mark as unread",
  "lang_load_img": "Show images",
  "lang_open_window": "Open",
  "lang_next": "Next",
  "lang_share_native_label": "Share",
  "lang_share_diaspora_label": "Share on Diaspora",
  "lang_share_twitter_label": "Share on Twitter",
  "lang_share_facebook_label": "Share on Facebook",
  "lang_share_pocket_label": "Save to Pocket",
  "lang_share_wallabag_label": "Save to Wallabag",
  "lang_share_wordpress_label": "Share on WordPress",
  "lang_share_mail_label": "Share using e-mail",
  "lang_share_copy_label": "Copy link to clipboard",
  "lang_search_label": "Search term",
  "lang_searchbutton": "Search",
  "lang_searchremove": "Hide search",
  "lang_refreshbutton": "Refresh sources",
  "lang_settingsbutton": "Settings",
  "lang_logoutbutton": "Log out",
  "lang_loginbutton": "Log in",
  "lang_sources": "Sources",
  "lang_source_add": "Add source",
  "lang_source_opml": "or import an OPML file",
  "lang_source_export": "Export sources",
  "lang_source_edit": "Edit",
  "lang_source_filter": "Filter",
  "lang_source_delete": "Delete",
  "lang_source_warn": "Really delete this source?",
  "lang_source_new": "New unsaved source",
  "lang_source_title": "Title",
  "lang_source_autotitle_hint": "Leave empty to fetch title",
  "lang_source_tags": "Tags",
  "lang_source_pwd_placeholder": "Not changed",
  "lang_source_comma": "Comma separated",
  "lang_source_select": "Please select source",
  "lang_source_type": "Type",
  "lang_source_save": "Save",
  "lang_source_cancel": "Cancel",
  "lang_source_saved": "Saved",
  "lang_source_last_post": "Last post seen",
  "lang_source_refresh": "Refresh this source",
  "lang_no_entries": "No entries found",
  "lang_more": "More",
  "lang_login": "Log in",
  "lang_login_username": "Username",
  "lang_login_password": "Password",
  "lang_login_invalid_credentials": "Wrong username/password",
  "lang_login_offline": "Offline storage",
  "lang_no_title": "No title",
  "lang_experimental": "Experimental",
  "lang_error": "An error occurred",
  "lang_error_check_log_file": "An error occured, please check the log file “{0}”.",
  "lang_error_check_system_logs": "An error occured, please check your system logs.",
  "lang_error_unwritable_logs": "Unable to write logs.",
  "lang_streamerror": "No items loaded, reload?",
  "lang_reload_list": "Reload list",
  "lang_sources_refreshed": "Sources refreshed",
  "lang_app_update": "selfoss has been updated, please reload",
  "lang_app_reload": "Reload",
  "lang_error_session_expired": "Session expired",
  "lang_error_add_source": "Could not add source:",
  "lang_error_edit_source": "Could not save source changes:",
  "lang_error_delete_source": "Could not delete source:",
  "lang_error_load_tags": "Could not load tags:",
  "lang_error_unknown_tag": "Unknown tag:",
  "lang_error_unknown_source": "Unknown source ID:",
  "lang_error_mark_items": "Cannot mark all visible items:",
  "lang_error_mark_item": "Cannot mark/unmark item:",
  "lang_error_star_item": "Cannot star/unstar item:",
  "lang_error_logout": "Could not log out:",
  "lang_error_loading": "Loading error:",
  "lang_error_loading_stats": "Cannot load nav stats:",
  "lang_error_saving_color": "Cannot save new color:",
  "lang_error_refreshing_source": "Cannot refresh sources:",
  "lang_error_sync": "Could not sync last changes from server:",
  "lang_error_offline_storage": "Offline storage error: {0}. Reloading may help. Disabling offline for now.",
  "lang_error_offline_storage_not_available": "Offline storage is not available. Make sure your web browser {0}supports IndexedDB API{1} and, if you are using Google Chrome based browser, that you are not running selfoss in incognito mode.",
  "lang_error_invalid_subsection": "Invalid subsection:",
  "lang_error_configuration": "Unable to load configuration.",
  "lang_error_share_native_abort": "Unable to share item—either there are no share targets, or you cancelled it.",
  "lang_error_share_native": "Unable to share item.",
  "lang_info_url_copied": "URL copied to clipboard.",
  "lang_close_entry": "Close",
  "lang_article_actions": "Actions",
  "lang_article_reading_time": "{0} min read"
}
;