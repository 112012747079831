module.exports = {
  "lang_markread": "позначити як прочитані",
  "lang_newest": "нові",
  "lang_unread": "не прочитані",
  "lang_starred": "відзначені",
  "lang_tags": "Теги",
  "lang_alltags": "усі теги",
  "lang_timestamp": "{0,date} {0,time}",
  "lang_hours": "{0,plural,one {1 годину},other {# години}} тому",
  "lang_minutes": "{0,plural,one {1 хвилину},other {# хвилини}} тому",
  "lang_seconds": "{0,plural,one {1 секунду},other {# секунди}} тому",
  "lang_star": "відзначити",
  "lang_unstar": "зняти позначку",
  "lang_mark": "прочитане",
  "lang_unmark": "не прочитане",
  "lang_load_img": "завантажити зображення",
  "lang_open_window": "відкрити",
  "lang_searchbutton": "пошук",
  "lang_sources": "Джерела",
  "lang_source_add": "додати джерело",
  "lang_source_opml": "або імпортувати з OPML файлу",
  "lang_source_export": "експорт джерел",
  "lang_source_edit": "редагувати",
  "lang_source_delete": "видалити",
  "lang_source_warn": "дійсно видалити це джерело?",
  "lang_source_new": "нове не збережене джерело",
  "lang_source_title": "Назва",
  "lang_source_tags": "Теги",
  "lang_source_comma": "розілені комою",
  "lang_source_select": "виберіть тип джерела",
  "lang_source_type": "Тип",
  "lang_source_save": "зберегти",
  "lang_source_cancel": "відміна",
  "lang_source_saved": "збережено",
  "lang_no_entries": "не знайдено записів",
  "lang_more": "ще",
  "lang_login": "вхід",
  "lang_login_username": "ім'я",
  "lang_login_password": "пароль",
  "lang_no_title": "без назви"
}
;